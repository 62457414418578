import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: inline-block;
  width: 100%;
`;

export const Icon = styled.div`
  > div > div {
    display: flex;
    align-items: center;
  }

  svg {
    width: 24px;
    height: 24px;
    fill: ${({ theme }) => theme.primary};
    margin-right: 12px;
  }

  ${({ tooltip }) =>
    tooltip &&
    css`
      svg {
        width: 20px;
        height: 20px;
        fill: #fff;
        margin-right: 0;
      }
    `}
`;

export const Info = styled.div`
  display: flex;
  align-items: center;
`;

export const Description = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 50px;
  padding: 6px 12px;
  font-size: 1.6rem;
  font-weight: ${({ theme }) => theme.fontWeight.black};
  text-transform: uppercase;
  background-color: #000;
  color: #fff;
  line-height: 1;

  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    min-height: ${({ large }) => (large ? '64px' : '50px')};
    font-size: ${({ small }) => (small ? '1.6rem' : '2rem')};
  }

  > * {
    flex: 1;
  }

  > ${Icon} {
    flex: 0;
  }
`;

export const Input = styled.input`
  position: absolute;
  top: auto;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  white-space: nowrap;

  :checked {
    ~ label {
      ${Description} {
        background-color: ${({ theme }) => theme.primary};

        ${Info} {
          ${Icon} {
            svg {
              fill: #000;
            }
          }
        }
      }
    }
  }
`;

export const Label = styled.label`
  position: relative;
  display: inline-block;
  width: 100%;

  :hover {
    cursor: pointer;
  }

  ${({ image }) =>
    image &&
    css`
      padding-bottom: 100%;
      background: url(${image}) center / cover no-repeat;

      ${Description} {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      :hover {
        cursor: not-allowed;
      }

      ${Description} {
        background-color: ${({ theme }) => theme.disabled};
      }
    `}
`;
