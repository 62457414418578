import React, { Component } from 'react';
import { WBoxContainer, WBoxRow, WBoxCol, WBoxLayout } from './WBox.styled';
import Empty from './Empty/Empty';
import Add from './Add/Add';
import Item from './Item/Item';
import Select from '../Select/Select';
import { getCheckedItem } from '../../helpers/helpers';

class WBox extends Component {
  isHorizontal() {
    const { distances } = this.props;
    const { horizontal } = getCheckedItem(distances);

    return horizontal;
  }

  isVertical() {
    const { distances } = this.props;
    const { vertical } = getCheckedItem(distances);

    return vertical;
  }

  getLayoutList() {
    const { layouts, translations } = this.props;

    if (this.isHorizontal()) {
      const horizontalLayout = layouts.find(el => el.id === '1x2');
      if (horizontalLayout) {
        horizontalLayout.checked = true;
      }
    }

    return layouts
      .filter(el => {
        if (!this.isVertical() && +el.id.split('x')[1] === 1) {
          return false;
        }

        return true;
      })
      .map(el => {
        const layoutDimensions = el.id.split('x');
        const rows = layoutDimensions[0];
        const cols = layoutDimensions[1];
        el.title = `${el.id} 
        (${rows} ${rows > 1 ? translations.rows : translations.row} ${cols} 
        ${cols > 1 ? translations.windows : translations.window})`.toLowerCase();

        return el;
      });
  }

  getCheckedLayout() {
    const { layouts } = this.props;
    const layout = getCheckedItem(layouts);

    if (layout) return layout.id;

    return 'none';
  }

  getColsLength() {
    const { windows } = this.props;
    return windows[0].length;
  }

  render() {
    const { windows, sizes, changeLayoutFn, handleClickItemFn } = this.props;
    return (
      <>
        <WBoxLayout>
          <Select
            options={this.getLayoutList()}
            value={this.getCheckedLayout()}
            onChange={({ target: { value } }) => changeLayoutFn(value)}
            disabled={this.isHorizontal()}
          />
        </WBoxLayout>
        {windows.length > 0 && (
          <WBoxContainer cols={this.getColsLength()} rows={windows.length}>
            {[...windows].reverse().map((row, rowIndex) => (
              <WBoxRow key={`row-${rowIndex}`} cols={this.getColsLength()}>
                {row.map(item => (
                  <WBoxCol key={item.pos}>
                    {item.type === 'empty' && <Empty position={item.pos} />}
                    {item.type === 'add' && (
                      <Add
                        cols={this.getColsLength()}
                        position={item.pos}
                        sizes={sizes}
                        windows={windows}
                      />
                    )}
                    {item.type === 'item' && (
                      <Item
                        cols={this.getColsLength()}
                        position={item.pos}
                        sizeId={item.sizeId}
                        handleClickItemFn={handleClickItemFn}
                      />
                    )}
                  </WBoxCol>
                ))}
              </WBoxRow>
            ))}
          </WBoxContainer>
        )}
      </>
    );
  }
}

export default WBox;
