import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Container, Section, LoaderView } from '../../layout';
import { fetchView as fetchViewAction } from '../../store/views/summary';
import SummaryTable from '../../components/SummaryTable/SummaryTable';
import { getCheckedItem } from '../../helpers/helpers';
import { changeView as changeViewAction } from '../../store/actions';
import Results from '../../components/Results/Results';
import WBoxResults from '../../components/WBoxResults/WBoxResults';

class Summary extends Component {
  componentDidMount() {
    const { fetchView } = this.props;
    fetchView();
  }

  handleClickChangeView(path) {
    const { changeView } = this.props;
    changeView(path);
  }

  getRoofingType() {
    const { roofingTypes } = this.props;
    return getCheckedItem(roofingTypes);
  }

  getDistance() {
    const { distances } = this.props;
    return getCheckedItem(distances);
  }

  getWindowsGroupBy(type) {
    const windows = this.getClearWindows();
    const windowsGroup = [];

    windows.forEach(row => {
      row.forEach(col => {
        const index = windowsGroup.findIndex(el => el[type] === col[type]);
        if (index !== -1) {
          windowsGroup[index].count++;
        } else {
          windowsGroup.push({
            ...col,
            count: 1,
          });
        }
      });
    });

    return windowsGroup;
  }

  getItemDefault(item, y, x, xMax, distance) {
    if (y === 0 && (x === 0 || x === xMax)) {
      return {
        ...item,
        result: `${distance.type} ${item.title} ${
          distance.horizontal ? parseInt(distance.title) : '13'
        }${distance.code}`,
        type: 'outside',
      };
    }

    if (y > 0 && (x === 0 || x === xMax)) {
      return {
        ...item,
        result: `${distance.type} ${item.title} 46${distance.code}E`,
        type: 'outside',
      };
    }

    if (y === 0 && (x > 0 && x <= xMax)) {
      return {
        ...item,
        result: `${distance.type} ${item.title} 2${distance.code}`,
        type: 'inside-bottom',
      };
    }

    if (y > 0 && (x > 0 && x <= xMax)) {
      return {
        ...item,
        result: `UCX ${item.title} 5${distance.code}E`,
        type: 'inside-bottom',
      };
    }

    return item;
  }

  getItemLine(item, y, distance) {
    if (y === 0) {
      return {
        ...item,
        result: `${distance.type.toLowerCase() === 'ucx' ? 'kuf' : 'kls'} ${item.title}`,
        type: 'line',
      };
    }

    if (y > 0) {
      return {
        ...item,
        result: `${distance.type} ${item.title} 7E`,
        type: 'line',
      };
    }

    return item;
  }

  getClearWindows() {
    const { windows, sizes } = this.props;
    const distance = this.getDistance();
    const layoutXLenght = windows[0].length;

    return windows
      .map(row => {
        return row.map(col => {
          return sizes.find(size => size.id === col.sizeId);
        });
      })
      .map((row, y) => {
        const xMax = row.length - 1;
        return row.map((item, x) => {
          if (layoutXLenght === 1) {
            return this.getItemLine(item, y, distance);
          }

          return this.getItemDefault(item, y, x, xMax, distance);
        });
      });
  }

  getResultWindows() {
    const windows = this.getWindowsGroupBy('result');

    windows.forEach(el => {
      if (el.type === 'outside' && el.count > 1) {
        el.count /= 2;
      }
    });

    return windows;
  }

  getResultLink() {
    const { translations, urls } = this.props;
    const distance = this.getDistance();

    switch (distance.type.toLowerCase()) {
      case 'scx':
        return {
          url: urls.scx,
          title: translations.scxUrlTitle,
        };
      case 'ubx':
        return {
          url: urls.ubx,
          title: translations.ubxUrlTitle,
        };
      case 'ucx':
        return {
          url: urls.ucx,
          title: translations.ucxUrlTitle,
        };
      default:
        return null;
    }
  }

  render() {
    const { viewLoading, translations } = this.props;
    return (
      <>
        {viewLoading ? (
          <LoaderView />
        ) : (
          <>
            <Section>
              <Container>
                <SummaryTable
                  changeViewFn={path => this.handleClickChangeView(path)}
                  translations={translations}
                  roofingType={this.getRoofingType()}
                  distance={this.getDistance()}
                  windows={this.getWindowsGroupBy('id')}
                />
              </Container>
              <Container>
                <Results
                  translations={translations}
                  windowsGroup={this.getResultWindows()}
                  link={this.getResultLink()}
                />
              </Container>
              <Container>
                <WBoxResults windows={this.getClearWindows()} />
              </Container>
            </Section>
          </>
        )}
      </>
    );
  }
}

Summary.propTypes = {
  fetchView: PropTypes.func.isRequired,
  viewLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = ({
  viewLoading,
  translations,
  roofingTypes,
  distances,
  windows,
  sizes,
  urls,
}) => ({
  viewLoading,
  translations,
  roofingTypes,
  distances,
  windows,
  sizes,
  urls,
});

const mapDispatchToProps = dispatch => {
  return {
    fetchView: () => dispatch(fetchViewAction()),
    changeView: path => dispatch(changeViewAction(path)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Summary);
