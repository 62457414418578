import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Container, Row, Section, LoaderView } from '../../layout';
import { DistanceCol, DistanceImg, DistanceItem } from './Distance.styled';
import Title from '../../components/Title/Title';
import Radio from '../../components/Radio/Radio';
import { distanceImage } from '../../assets';
import {
  fetchView as fetchViewAction,
  setDistance as setDistanceAction,
  validateStepDistance as validateStepDistanceAction,
} from '../../store/views/distance';

class Distance extends Component {
  componentDidMount() {
    const { fetchView, viewFetched, validateStepDistance } = this.props;

    if (!viewFetched) {
      fetchView();
    }

    validateStepDistance();
  }

  render() {
    const { viewLoading, translations, setDistance, distances } = this.props;

    return (
      <>
        {viewLoading ? (
          <LoaderView />
        ) : (
          <>
            <Section>
              <Container>
                <Row>
                  <DistanceCol>
                    <Title as="h2" margin left>
                      {translations.horizontalDistance}
                    </Title>
                    {distances.map(item => (
                      <DistanceItem key={item.id}>
                        <Radio
                          id={`distance${item.id}`}
                          name="distance"
                          value={item.id}
                          title={item.title}
                          tooltip={item.tooltip}
                          checked={item.checked}
                          onChange={() => setDistance(item.id)}
                        />
                      </DistanceItem>
                    ))}
                  </DistanceCol>
                  <DistanceCol image>
                    <DistanceImg src={distanceImage} />
                  </DistanceCol>
                </Row>
              </Container>
            </Section>
          </>
        )}
      </>
    );
  }
}

Distance.propTypes = {
  fetchView: PropTypes.func.isRequired,
  viewLoading: PropTypes.bool.isRequired,
  viewFetched: PropTypes.bool,
  distances: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      title: PropTypes.string.isRequired,
      tooltip: PropTypes.string,
      code: PropTypes.string.isRequired,
      checked: PropTypes.bool.isRequired,
    }),
  ),
  translations: PropTypes.shape(),
  validateStepDistance: PropTypes.func.isRequired,
  setDistance: PropTypes.func.isRequired,
};

Distance.defaultProps = {
  viewFetched: false,
  translations: {},
  distances: [],
};

const mapStateToProps = ({
  viewLoading,
  views: {
    distance: { fetched: viewFetched },
  },
  translations,
  distances,
}) => ({ viewLoading, viewFetched, translations, distances });

const mapDispatchToProps = dispatch => {
  return {
    fetchView: pageId => dispatch(fetchViewAction(pageId)),
    validateStepDistance: () => dispatch(validateStepDistanceAction()),
    setDistance: id => dispatch(setDistanceAction(id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Distance);
