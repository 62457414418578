import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { connect } from 'react-redux';

const SelectWrapper = styled.div`
  position: relative;

  ::after {
    width: 0;
    height: 0;

    pointer-events: none;
    content: '';
    position: absolute;
    top: 20px;
    right: 16px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 9px solid ${({ theme }) => theme.primary};

    @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
      top: ${({ small }) => (small ? '20px' : '25px')};
    }
  }
`;

const SelectTag = styled.select`
  font-size: 1.4rem;
  line-height: 20px;
  height: 48px;
  border: 2px solid #000;
  box-shadow: none;
  background: none;
  appearance: none;
  width: 100%;
  border-radius: 0;
  padding-left: 16px;
  padding-right: 46px;
  font-weight: ${({ theme }) => theme.fontWeight.regular};

  :focus {
    outline: none !important;
    border-color: ${({ theme }) => theme.primary}; !important;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    font-size: 1.8rem;
    height: ${({ small }) => (small ? '48px' : '54px')};
  }
  
  ${({ disabled }) =>
    disabled &&
    css`
      border-color: ${({ theme }) => theme.disabled};
    `}
`;

const Error = styled.span`
  display: block;
  margin-top: 4px;
  font-size: 1.2rem;
  color: #f00;
`;

const Select = ({
  options,
  small,
  value,
  disabled,
  error,
  className,
  translations,
  placeholder,
  ...props
}) => (
  <SelectWrapper small={small} className={className}>
    <SelectTag small={small} {...props} value={value} disabled={disabled}>
      <option value="none">{placeholder || translations.selectFromList}</option>
      {options.map(item => (
        <option key={`sel${item.id}`} value={item.id}>
          {item.title}
        </option>
      ))}
    </SelectTag>
    {error && <Error>{error}</Error>}
  </SelectWrapper>
);

Select.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

const mapStateToProps = ({ translations }) => ({
  translations,
});

export default connect(mapStateToProps)(Select);
