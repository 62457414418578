import React from 'react';
import PropTypes from 'prop-types';
import ReactSVG from 'react-svg';
import { iconList } from '../../assets';
import { ButtonWrapper, LoaderIcon } from './Button.styled';

const Button = ({ icon, children, loading, ...props }) => (
  <ButtonWrapper icon={!!icon} {...props}>
    {loading ? (
      <LoaderIcon />
    ) : (
      <>
        {icon && <ReactSVG src={iconList[icon]} />}
        {children}
      </>
    )}
  </ButtonWrapper>
);

Button.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.string,
  loading: PropTypes.bool,
};

Button.defaultProps = {
  children: '',
  icon: '',
  loading: false,
};

export default Button;
