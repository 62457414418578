import styled, { css } from 'styled-components';

export const Wrapper = styled.a`
  ${({ theme }) =>
    theme &&
    css`
      display: inline-flex;
      align-items: center;
      font-size: 1.6rem;
      font-weight: ${theme.fontWeight.medium};
      color: ${theme.primary};
      margin-top: 32px;

      :hover {
        color: ${theme.primaryHover};
        transition: ${theme.btnTransition};

        svg {
          fill: ${theme.primaryHover};
        }
      }

      @media (min-width: ${theme.breakpoints.xl}) {
        font-size: 2rem;
      }

      svg {
        width: 18px;
        height: 11px;
        fill: ${theme.primary};
        margin-left: 6px;
        transition: ${theme.btnTransition};
      }
    `}
`;
