import axios from 'axios';
import { getLangCode } from './lang';

export const API_PATHS = {
  settings: '/combi/v1/global-settings',
  pages: '/acf/v3/pages/',
  translation: '/configurator/v1/translate',
  form: '/contact-form-7/v1/contact-forms/:id/feedback',
  roofing: 'wp/v2/combi_roofing_type',
  distance: '/wp/v2/combi-distance',
  sizes: '/wp/v2/combi',
};

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL_BASE,
  headers: {
    'icl-lang-code': getLangCode(),
  },
});
