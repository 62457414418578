import React, { Component } from 'react';
import { Table, TableCol, TableContent, TableHeader, ButtonTable } from './SummaryTable.styled';

class SummaryTable extends Component {
  render() {
    const { changeViewFn, translations, roofingType, distance, windows } = this.props;
    return (
      <Table>
        <TableCol>
          <TableHeader>
            <ButtonTable icon="edit" onClick={() => changeViewFn('/roofing')}>
              {translations.roofingType}
            </ButtonTable>
          </TableHeader>
          <TableContent>
            <p>
              <strong>{translations.roofingType}:</strong> {roofingType.title}
            </p>
          </TableContent>
        </TableCol>
        <TableCol>
          <TableHeader>
            <ButtonTable icon="edit" onClick={() => changeViewFn('/distance')}>
              {translations.horizontalDistance}
            </ButtonTable>
          </TableHeader>
          <TableContent>
            <p>
              <strong>{translations.horizontalDistance}:</strong> {distance.title}
            </p>
          </TableContent>
        </TableCol>
        <TableCol>
          <TableHeader>
            <ButtonTable icon="edit" onClick={() => changeViewFn('/windows')}>
              {translations.selectedWindows}
            </ButtonTable>
          </TableHeader>
          <TableContent>
            {windows.map(item => (
              <p key={`win-${item.id}`}>
                <strong>{item.title} </strong> ({item.dimension.width} x {item.dimension.height})
                <strong style={{ textTransform: 'lowercase' }}> x{item.count}</strong>
              </p>
            ))}
          </TableContent>
        </TableCol>
      </Table>
    );
  }
}

export default SummaryTable;
