import styled, { css } from 'styled-components';

export const ItemTitle = styled.p`
  font-size: 20px;
  font-weight: ${({ theme }) => theme.fontWeight.black};
  text-transform: uppercase;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-size: 24px;
  }
`;

export const ItemDimension = styled.p`
  font-size: 14px;
  font-weight: ${({ theme }) => theme.fontWeight.medium};

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-size: 18px;
  }
`;

export const ItemBox = styled.button`
  width: 100%;
  height: 100%;
  background-color: #fff;
  border: 4px solid #000;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  ${({ cols }) =>
    cols > 4 &&
    css`
      @media (max-width: ${({ theme }) => `${parseInt(theme.breakpoints.sm) - 1}px`}) {
        border-width: 2px;

        ${ItemTitle} {
          font-size: 14px;
        }

        ${ItemDimension} {
          font-size: 10px;
        }
      }
    `}
`;
