import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Container, Section, LoaderView } from '../../layout';
import Title from '../../components/Title/Title';
import {
  fetchView as fetchViewAction,
  changeLayout as changeLayoutAction,
  removeWindow as removeWindowAction,
  validateStepWindows as validateStepWindowsAction,
} from '../../store/views/windows';
import WBox from '../../components/WBox/WBox';

class Windows extends Component {
  componentDidMount() {
    const { fetchView, viewFetched, validateStepWindows } = this.props;

    if (!viewFetched) {
      fetchView();
    }

    validateStepWindows();
  }

  handleChangeLayout(id) {
    const { changeLayout } = this.props;
    changeLayout(id);
  }

  handleClickItem(position) {
    const { removeWindow } = this.props;
    removeWindow(position);
  }

  render() {
    const { viewLoading, translations, sizes, windows, layouts, distances } = this.props;
    return (
      <>
        {viewLoading ? (
          <LoaderView />
        ) : (
          <>
            <Section>
              <Container>
                <Title as="h2" margin>
                  {translations.windowArrangement}
                </Title>
              </Container>
              <Container>
                <WBox
                  translations={translations}
                  sizes={sizes}
                  windows={windows}
                  layouts={layouts}
                  distances={distances}
                  changeLayoutFn={id => this.handleChangeLayout(id)}
                  handleClickItemFn={position => this.handleClickItem(position)}
                />
              </Container>
            </Section>
          </>
        )}
      </>
    );
  }
}

Windows.propTypes = {
  fetchView: PropTypes.func.isRequired,
  viewLoading: PropTypes.bool.isRequired,
  viewFetched: PropTypes.bool,
  translations: PropTypes.shape(),
  validateStepWindows: PropTypes.func.isRequired,
};

Windows.defaultProps = {
  viewFetched: false,
  translations: {},
};

const mapStateToProps = ({
  viewLoading,
  views: {
    windows: { fetched: viewFetched, data: viewData },
  },
  translations,
  sizes,
  windows,
  layouts,
  distances,
}) => ({ viewLoading, viewFetched, viewData, translations, sizes, windows, layouts, distances });

const mapDispatchToProps = dispatch => {
  return {
    fetchView: () => dispatch(fetchViewAction()),
    changeLayout: id => dispatch(changeLayoutAction(id)),
    removeWindow: position => dispatch(removeWindowAction(position)),
    validateStepWindows: () => dispatch(validateStepWindowsAction()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Windows);
