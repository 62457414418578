import React from 'react';
import ReactSVG from 'react-svg';
import { iconList } from '../../assets';
import { Wrapper } from './Link.styled';

const Link = ({ href, icon, children, ...props }) => (
  <Wrapper href={href} {...props}>
    <>
      {children}
      {icon && <ReactSVG src={iconList[icon]} />}
    </>
  </Wrapper>
);

export default Link;
