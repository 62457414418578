import React, { Component } from 'react';
import { ItemBox, ItemTitle, ItemDimension } from './Item.styled';

class Item extends Component {
  getSizeName() {
    const { size } = this.props;
    return size ? size.title : '';
  }

  getSizeDimension() {
    const { size } = this.props;
    return size ? `${size.dimension.width} x ${size.dimension.height} cm` : '';
  }

  render() {
    const { cols } = this.props;

    return (
      <ItemBox cols={cols}>
        <div>
          <ItemTitle>{this.getSizeName()}</ItemTitle>
          <ItemDimension>{this.getSizeDimension()}</ItemDimension>
        </div>
      </ItemBox>
    );
  }
}

export default Item;
