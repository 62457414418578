import { getCheckedItem } from '../helpers/helpers';
import initialState from './initialState';

const setItemWindow = (windows, y, x, sizeId) => {
  windows[y][x].type = 'item';
  windows[y][x].sizeId = sizeId;
};

const setEmptyWindow = (windows, y, x) => {
  windows[y][x].type = 'empty';
  windows[y][x].sizeId = null;
};

const isPrevRowFilled = (windows, y) => {
  if (y > 0) {
    const empty = windows[y - 1].filter(el => el.type !== 'item');

    return empty.length === 0;
  }

  return true;
};

const removeInsideWindows = (windows, y) => {
  for (let x = 1; x <= windows[y].length - 2; x++) {
    setEmptyWindow(windows, y, x);
  }
};

const removeOutsideWindows = (windows, y) => {
  const lastColIndex = windows[y].length - 1;

  setEmptyWindow(windows, y, 0);
  setEmptyWindow(windows, y, lastColIndex);
};

const removeAboveRow = (windows, y) => {
  if (y < windows.length - 1) {
    for (let row = y + 1; row < windows.length; row++) {
      windows[row].forEach((col, x) => {
        setEmptyWindow(windows, row, x);
      });
    }
  }
};

export const reducerAddWindow = (state, action) => {
  const { windows } = state;
  const { position, sizeId } = action.payload;
  const y = +position[0];
  const x = +position[1];
  const lastColIndex = windows[y].length - 1;

  setItemWindow(windows, y, x, sizeId);

  if (x === 0) {
    setItemWindow(windows, y, lastColIndex, sizeId);
  }

  if (x === 1) {
    for (let i = x; i < lastColIndex; i++) {
      setItemWindow(windows, y, i, sizeId);
    }
  }

  return {
    ...state,
    windows: JSON.parse(JSON.stringify(windows)),
  };
};

export const reducerRemoveWindow = (state, action) => {
  const { windows } = state;
  const { position } = action.payload;
  const y = +position[0];
  const x = +position[1];
  const lastColIndex = windows[y].length - 1;

  removeInsideWindows(windows, y);

  if (x === 0 || x === lastColIndex) {
    removeOutsideWindows(windows, y);

    if (y === 0) {
      windows[0][0].type = 'add';
    }
  }

  removeAboveRow(windows, y);

  return {
    ...state,
    windows: JSON.parse(JSON.stringify(windows)),
  };
};

export const reducerFillAddWindows = state => {
  const { windows } = state;

  windows.forEach((row, y) => {
    row.map((col, x) => {
      if (col.type !== 'item') {
        if (row[0].type === 'item' && x === 1) {
          col.type = 'add';
        }

        if (y > 0 && x === 0 && isPrevRowFilled(windows, y)) {
          col.type = 'add';
        }
      }

      return col;
    });

    return row;
  });

  return {
    ...state,
    windows: JSON.parse(JSON.stringify(windows)),
  };
};

export const reducerFillLayout = state => {
  const { layouts } = state;
  const layout = getCheckedItem(layouts);

  const windows = JSON.parse(JSON.stringify(initialState.windows));

  if (layout) {
    const { id } = layout;
    const layoutDimensions = id.split('x');
    const yMax = layoutDimensions[0];
    const xMax = layoutDimensions[1];

    for (let x = 0; x < yMax; x++) {
      const row = [];
      for (let y = 0; y < xMax; y++) {
        if (x === 0 && y === 0) {
          row.push({
            pos: `${x}${y}`,
            type: 'add',
            sizeId: null,
          });
        } else {
          row.push({
            pos: `${x}${y}`,
            type: 'empty',
            sizeId: null,
          });
        }
      }
      windows.push(row);
    }
  }

  return {
    ...state,
    windows,
  };
};
