import styled, { css } from 'styled-components';
import Container from '../../layout/Container/Container';
import Col from '../../layout/Col/Col';
import Row from '../../layout/Row/Row';
import Title from '../Title/Title';

const FooterWrapper = styled.div`
  padding: 32px 0 16px;
  background-color: #000;
  z-index: 99;

  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    height: 128px;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
  }
`;

const FooterContainer = styled(Container)`
  height: 100%;
`;

const FooterRow = styled(Row)`
  height: 100%;
  align-items: center;
`;

const FooterCol = styled(Col)`
  text-align: center;
  flex: 1 1 auto;
  width: auto;

  button {
    margin-bottom: 16px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    text-align: left;
  }

  ${({ right }) =>
    right &&
    css`
      @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        text-align: right;
      }
    `}
`;

const TitleFooter = styled(Title)`
  color: #fff;
  margin-bottom: 16px;
  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    text-align: left;
    font-size: 4rem;
  }
`;

const FooterButtons = styled.div`
  margin: 0 -16px;

  button {
    margin-left: 16px;
    margin-right: 16px;
  }
`;

export { FooterWrapper, FooterContainer, FooterRow, FooterCol, TitleFooter, FooterButtons };
