export const getLangCode = () => {
  const domain = window.location.hostname;

  switch (domain) {
    case 'dakea.co.uk':
      return 'en';
    case 'dakea.pl':
      return 'pl';
    case 'dakea.sk':
      return 'sk';
    case 'dakea.nl':
      return 'nl';
    case 'dakea.hu':
      return 'hu';
    case 'dakea.cz':
      return 'cs';
    case 'dakea.ro':
      return 'ro';
    case 'dakea.fr':
      return 'fr';
    case 'dakea.es':
      return 'es';
    case 'dakea.de':
      return 'de';
    case 'localhost':
    case 'dakea-combi-configurator.dev.greenparrot.pl':
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.has('l') ? urlParams.get('l') : 'en';
    default:
      return 'en';
  }
};
