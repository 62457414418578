import React from 'react';
import PropTypes from 'prop-types';
import ReactSVG from 'react-svg';
import Tooltip from '../Tooltip/Tooltip';
import { Wrapper, Input, Label, Description, Icon, Info } from './Radio.styled';

const Radio = ({ type, id, title, icon, image, tooltip, small, large, disabled, ...props }) => (
  <Wrapper>
    <Input type={type} id={id} {...props} disabled={disabled} />
    <Label htmlFor={id} image={image} disabled={disabled}>
      <Description small={small} large={large}>
        <Info>
          {icon && (
            <Icon>
              <ReactSVG src={icon} />
            </Icon>
          )}
          {title}
        </Info>
        {tooltip && (
          <Icon tooltip>
            <Tooltip placement="right" trigger="click">
              {tooltip}
            </Tooltip>
          </Icon>
        )}
      </Description>
    </Label>
  </Wrapper>
);

Radio.propTypes = {
  type: PropTypes.string,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  image: PropTypes.string,
  tooltip: PropTypes.string,
  small: PropTypes.bool,
  large: PropTypes.bool,
  disabled: PropTypes.bool,
};

Radio.defaultProps = {
  type: 'checkbox',
  icon: null,
  image: null,
  tooltip: null,
  small: false,
  large: false,
  disabled: false,
};

export default Radio;
