import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Header from '../Header/Header';
import HeaderDescription from '../HeaderDescription/HeaderDescription';
import Title from '../Title/Title';

class HeaderPage extends Component {
  render() {
    const { activePath, views, viewLoading } = this.props;
    const activeView = activePath.split('/')[1];

    const { title, description } = views[activeView].header;

    return (
      !viewLoading && (
        <Header>
          {title && (
            <Title as="h2" primary>
              {title}
            </Title>
          )}

          {description && (
            <HeaderDescription dangerouslySetInnerHTML={{ __html: `${description}` }} />
          )}
        </Header>
      )
    );
  }
}

HeaderPage.propTypes = {
  activePath: PropTypes.string.isRequired,
  views: PropTypes.shape().isRequired,
  viewLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ activePath, views, viewLoading }) => ({
  activePath,
  views,
  viewLoading,
});

export default connect(mapStateToProps)(HeaderPage);
