import React, { Component } from 'react';
import { ItemBox, ItemTitle, ItemDimension } from './Item.styled';
import { connect } from 'react-redux';

class Item extends Component {
  getSize() {
    const { sizes, sizeId } = this.props;
    return sizes.find(size => size.id === sizeId);
  }

  getSizeName() {
    const size = this.getSize();
    return size ? size.title : '';
  }

  getSizeDimension() {
    const size = this.getSize();
    return size ? `${size.dimension.width} x ${size.dimension.height} cm` : '';
  }

  render() {
    const { cols, position, handleClickItemFn } = this.props;

    return (
      <ItemBox cols={cols} onClick={() => handleClickItemFn(position)}>
        <div>
          <ItemTitle>{this.getSizeName()}</ItemTitle>
          <ItemDimension>{this.getSizeDimension()}</ItemDimension>
        </div>
      </ItemBox>
    );
  }
}

const mapStateToProps = ({ sizes }) => ({
  sizes,
});

export default connect(mapStateToProps)(Item);
