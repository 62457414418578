import styled from 'styled-components';
import { Col } from '../../layout';

export const RoofingTypeCol = styled(Col)`
  margin-bottom: 12px;

  :last-of-type {
    margin-bottom: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-basis: 50%;
    margin-bottom: 24px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    flex-basis: 25%;
    margin-bottom: 24px;
  }
`;
