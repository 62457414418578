import initialState from '../store/initialState';

export const clearNextStepsReducer = (state, action) => {
  const { steps } = state;
  const currentStepIndex = steps.indexOf(action.payload.currentStepName);

  if (currentStepIndex >= 0) {
    for (let i = currentStepIndex + 1; i < steps.length; i++) {
      state.views[steps[i]].fetched = false;
      state.views[steps[i]].valid = false;
      state.views[steps[i]].data = {};

      if (i === 1) {
        state.distances = initialState.distances;
      }

      if (i === 2) {
        state.windows = JSON.parse(JSON.stringify(initialState.windows));
        state.layouts = JSON.parse(JSON.stringify(initialState.layouts));
      }
    }
  }

  return state;
};

export const setCheckedOneById = (list, itemId) => {
  return list.map(item => {
    item.checked = item.id === itemId ? !item.checked : false;

    return item;
  });
};

export const isOneChecked = list => {
  return !!list.find(item => item.checked);
};
