import React from 'react';
import PropTypes from 'prop-types';
import TooltipTrigger from 'react-popper-tooltip';
import 'react-popper-tooltip/dist/styles.css';
import ReactSVG from 'react-svg';
import { iconList } from '../../assets';
import {
  TooltipContainer,
  TooltipArrow,
  TooltipContent,
  TooltipButton,
  TooltipButtonSize,
  IconSize,
} from './Tooltip.styled';

const Tooltip = ({ innerRef, sizes, cols, children, ...props }) => (
  <TooltipTrigger
    {...props}
    ref={innerRef}
    tooltip={({ arrowRef, tooltipRef, getArrowProps, getTooltipProps, placement }) => (
      <TooltipContainer
        {...getTooltipProps({
          ref: tooltipRef,
          sizes: sizes.toString(),
        })}
      >
        <TooltipArrow
          {...getArrowProps({
            ref: arrowRef,
            'data-placement': placement,
          })}
        />
        {sizes ? (
          <TooltipContent>{children}</TooltipContent>
        ) : (
          <TooltipContent dangerouslySetInnerHTML={{ __html: children }} />
        )}
      </TooltipContainer>
    )}
  >
    {({ getTriggerProps, triggerRef }) =>
      sizes ? (
        <TooltipButtonSize
          cols={cols}
          {...getTriggerProps({
            ref: triggerRef,
            className: 'trigger',
          })}
        >
          <IconSize>
            <ReactSVG src={iconList.add} />
          </IconSize>
        </TooltipButtonSize>
      ) : (
        <TooltipButton
          {...getTriggerProps({
            ref: triggerRef,
            className: 'trigger',
          })}
        >
          <ReactSVG src={iconList.tooltip} />
        </TooltipButton>
      )
    }
  </TooltipTrigger>
);

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  sizes: PropTypes.bool,
};

Tooltip.defaultProps = {
  sizes: false,
};

export default Tooltip;
