import styled, { css } from 'styled-components';

export const WBoxCol = styled.div`
  flex: 0 0 calc(100% / 2);
  display: flex;
  padding: 4px;
  max-width: 140px;

  :after {
    content: '';
    float: left;
    padding-bottom: 100%;
  }
`;

const getContainerWitdh = cols => {
  switch (cols) {
    case 2:
      return 280;
    case 3:
      return 360;
    case 4:
    case 5:
    case 6:
      return 560;
    default:
      return 280;
  }
};

export const WBoxContainer = styled.div`
  margin: 0 auto;
  width: 100%;

  ${({ cols }) =>
    cols &&
    css`
      @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
        width: ${getContainerWitdh(cols)}px;
      }
    `}
`;

export const WBoxRow = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;

  ${({ cols }) =>
    cols &&
    css`
      ${WBoxCol} {
        flex: 0 0 calc(100% / ${cols});
      }
    `}
`;

export const WBoxLayout = styled.div`
  text-align: center;
  max-width: 300px;
  margin: 0 auto 32px;
`;
