import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Container, Row, Section, LoaderView } from '../../layout';
import { RoofingTypeCol } from './Roofing.styled';
import Title from '../../components/Title/Title';
import Radio from '../../components/Radio/Radio';
import {
  fetchView as fetchViewAction,
  setRoofingType as setRoofingTypeAction,
  validateStepRoofing as validateStepRoofingAction,
} from '../../store/views/roofing';

class Roofing extends Component {
  componentDidMount() {
    const { fetchView, viewFetched, validateStepRoofing } = this.props;

    if (!viewFetched) {
      fetchView();
    }

    validateStepRoofing();
  }

  render() {
    const { viewLoading, translations, setRoofingType, roofingTypes } = this.props;

    return (
      <>
        {viewLoading ? (
          <LoaderView />
        ) : (
          <>
            <Section>
              <Container>
                <Title as="h2" margin>
                  {translations.roofingType}
                </Title>
                <Row center>
                  {roofingTypes.map(item => (
                    <RoofingTypeCol key={`roof${item.id}`}>
                      <Radio
                        id={`roof${item.id}`}
                        name="roofingTypes"
                        value={item.id}
                        title={item.title}
                        image={item.image}
                        tooltip={item.tooltip}
                        checked={item.checked}
                        onChange={() => setRoofingType(item.id)}
                      />
                    </RoofingTypeCol>
                  ))}
                </Row>
              </Container>
            </Section>
          </>
        )}
      </>
    );
  }
}

Roofing.propTypes = {
  fetchView: PropTypes.func.isRequired,
  viewLoading: PropTypes.bool.isRequired,
  viewFetched: PropTypes.bool,
  translations: PropTypes.shape(),
  validateStepRoofing: PropTypes.func.isRequired,
  setRoofingType: PropTypes.func.isRequired,
  roofingTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      title: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      tooltip: PropTypes.string,
      checked: PropTypes.bool.isRequired,
    }),
  ),
};

Roofing.defaultProps = {
  viewFetched: false,
  translations: {},
  roofingTypes: [],
};

const mapStateToProps = ({
  viewLoading,
  views: {
    roofing: { fetched: viewFetched },
  },
  translations,
  roofingTypes,
}) => ({ viewLoading, viewFetched, translations, roofingTypes });

const mapDispatchToProps = dispatch => {
  return {
    fetchView: pageId => dispatch(fetchViewAction(pageId)),
    validateStepRoofing: () => dispatch(validateStepRoofingAction()),
    setRoofingType: id => dispatch(setRoofingTypeAction(id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Roofing);
