import initialState from './initialState';
import { clearNextStepsReducer, setCheckedOneById } from '../helpers/store';
import { getActiveViewName } from '../helpers/helpers';
import {
  reducerAddWindow,
  reducerFillAddWindows,
  reducerFillLayout, reducerRemoveWindow,
} from './helpers';

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_APP_START':
      return {
        ...state,
        appLoading: true,
      };
    case 'FETCH_APP_SUCCESS':
      return {
        ...state,
        appLoading: false,
        appError: false,
        viewLoading: false,
        activePath: action.payload.data.views.roofing.path,
        ...action.payload.data,
      };
    case 'APP_ERROR':
      return {
        ...initialState,
        appError: true,
      };
    case 'RESET_APP':
      const roofingTypes = state.roofingTypes.map(item => {
        item.checked = false;
        return item;
      });

      return {
        ...state,
        activePath: state.views.roofing.path,
        roofingTypes,
        views: {
          roofing: {
            ...state.views.roofing,
            valid: false,
          },
          distance: {
            ...state.views.distance,
            fetched: false,
            valid: false,
          },
          windows: {
            ...state.views.windows,
            fetched: false,
            valid: false,
          },
          summary: {
            ...state.views.summary,
            fetched: false,
            valid: false,
          },
        },
      };
    case 'FETCH_VIEW_START':
      return {
        ...state,
        viewLoading: true,
      };
    case 'FETCH_EMPTY_VIEW_SUCCESS':
      return {
        ...state,
        viewLoading: false,
        views: {
          ...state.views,
          [action.payload.view]: {
            ...state.views[action.payload.view],
            fetched: true,
          },
        },
      };
    case 'FETCH_TRANSLATIONS_SUCCESS':
      return {
        ...state,
        translations: {
          ...state.translations,
          ...action.payload.data,
        },
      };
    case 'CHANGE_VIEW':
      return {
        ...state,
        activePath: action.payload.path,
      };
    case 'NEXT_STEP':
      const currentStepName = getActiveViewName(state.activePath);
      const currentStepIndex = state.steps.indexOf(currentStepName);
      return {
        ...state,
        activePath: state.views[state.steps[currentStepIndex + 1]].path,
      };
    case 'CLEAR_NEXT_STEPS':
      return clearNextStepsReducer(state, action);
    case 'VALIDATE_STEP':
      return {
        ...state,
        views: {
          ...state.views,
          [action.payload.stepName]: {
            ...state.views[action.payload.stepName],
            valid: action.payload.valid,
          },
        },
      };
    case 'GO_TO_SUMMARY':
      return {
        ...state,
        activePath: state.views.summary.path,
      };
    case 'SET_CHECKED_ITEM':
      const { viewName, listName, id } = action.payload;
      const items = setCheckedOneById(state.views[viewName].data[listName], id);

      return {
        ...state,
        views: {
          ...state.views,
          [viewName]: {
            ...state.views[viewName],
            data: {
              ...state.views[viewName].data,
              [listName]: items,
            },
          },
        },
      };
    case 'FETCH_SIZES_SUCCESS':
      return {
        ...state,
        sizes: action.payload.data,
      };
    case 'FETCH_ROOFINGS_TYPES_SUCCESS':
      return {
        ...state,
        roofingTypes: action.payload.data,
      };
    case 'FETCH_DISTANCES_SUCCESS':
      return {
        ...state,
        distances: action.payload.data,
      };
    case 'SET_ROOFING_TYPE':
      return {
        ...state,
        roofingTypes: setCheckedOneById(state.roofingTypes, action.payload.id),
      };
    case 'SET_DISTANCE':
      return {
        ...state,
        distances: setCheckedOneById(state.distances, action.payload.id),
      };
    case 'CHANGE_LAYOUT':
      return {
        ...state,
        layouts: setCheckedOneById(state.layouts, action.payload.id),
      };
    case 'FILL_LAYOUT':
      return reducerFillLayout(state);
    case 'ADD_WINDOW':
      return reducerAddWindow(state, action);
    case 'REMOVE_WINDOW':
      return reducerRemoveWindow(state, action);
    case 'FILL_ADD_WINDOWS':
      return reducerFillAddWindows(state);
    default:
      return state;
  }
};

export default reducers;
