import React from 'react';
import { Wrapper, Title, Content } from './Results.styled';
import Link from '../Link/Link';

const Results = ({ translations, windowsGroup, link }) => (
  <Wrapper>
    <Title dangerouslySetInnerHTML={{ __html: translations.configuratorResults }} />
    <Content>
      {windowsGroup.map(item => (
        <p key={`resultswin-${item.result}`}>
          {item.result}
          <strong style={{ textTransform: 'lowercase' }}> x{item.count}</strong>
        </p>
      ))}
    </Content>
    {link && (
      <Link href={link.url} icon="arrowRightLong" target="_blank">
        {link.title}
      </Link>
    )}
  </Wrapper>
);

export default Results;
