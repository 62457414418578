import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { TabsWrapper } from './Tabs.styled';
import Tab from './Tab/Tab';

const Tabs = ({ items, viewLoading, handleClickTab, ...props }) => (
  <TabsWrapper disabled={viewLoading} {...props}>
    {items.map(item => (
      <Tab
        key={item.path}
        {...item}
        disabled={viewLoading || item.disabled}
        handleClickTab={handleClickTab}
      />
    ))}
  </TabsWrapper>
);

Tabs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      number: PropTypes.number,
      title: PropTypes.string,
      active: PropTypes.bool,
    }),
  ).isRequired,
  viewLoading: PropTypes.bool,
  handleClickTab: PropTypes.func.isRequired,
};

Tabs.defaultProps = {
  viewLoading: false,
};

const mapStateToProps = ({ viewLoading }) => ({
  viewLoading,
});

export default connect(mapStateToProps)(Tabs);
