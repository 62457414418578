import React, { Component } from 'react';
import { EmptyBox } from './Empty.styled';

class Empty extends Component {
  render() {
    return <EmptyBox />;
  }
}

export default Empty;
