export const clearResponseSettings = data => {
  const viewsNames = ['roofing', 'distance', 'windows', 'summary'];
  const views = {};

  viewsNames.forEach((name) => {
    const viewObj = {
      path: `/${name}`,
      fetched: false,
      valid: false,
      header: {
        title: null,
        description: null,
      },
    };

    const viewData = data.views.find(view => view.slug === name);

    if (viewData) {
      viewObj.path = `/${name}`;
      viewObj.header.title = viewData.header_title;
      viewObj.header.description = viewData.header_description;
    }

    views[name] = viewObj;
  });

  data.views = views;
  data.urls = {
    scx: data.scxFlashingUlr,
    ubx: data.ubxFlashingUlr,
    ucx: data.ucxFlashingUlr,
  };

  delete data.scxFlashingUlr;
  delete data.ubxFlashingUlr;
  delete data.ucxFlashingUlr;

  return data;
};

export const clearResponseRoofingTypes = data => {
  const roofingTypes = [];

  data.forEach(el => {
    const { id, name, acf: fields } = el;
    roofingTypes.push({
      id,
      title: name,
      image: fields.image,
      tooltip: fields.tooltip,
      checked: false,
      distances: fields.available_distances,
    });
  });

  return roofingTypes;
};
