import { API_PATHS, axiosInstance } from '../../helpers/api';
import {
  appError,
  fetchViewStart,
  fetchTranslationsSuccess,
  validateStep,
  fetchEmptyViewSuccess,
  clearNextSteps,
} from '../actions';
import { fetchTranslations } from '../../helpers/translation';
import { isOneChecked } from '../../helpers/store';
import { clearResponseRoofingTypes } from '../clearResponse';

export const fetchView = () => async dispatch => {
  dispatch(fetchViewStart());

  const roofings = await axiosInstance
    .get(`${API_PATHS.roofing}`)
    .then(response => {
      return response.data;
    })
    .catch(() => {
      dispatch(appError());
    });

  const translationsList = [{ key: 'roofingType', value: 'Roofing type' }];

  const translations = await fetchTranslations(translationsList);

  dispatch(fetchTranslationsSuccess(translations));
  dispatch(fetchRoofingTypesSuccess(clearResponseRoofingTypes(roofings)));
  dispatch(fetchEmptyViewSuccess('roofing'));
};

export const fetchRoofingTypesSuccess = data => ({
  type: 'FETCH_ROOFINGS_TYPES_SUCCESS',
  payload: {
    data,
  },
});

export const setRoofingType = id => dispatch => {
  dispatch({
    type: 'SET_ROOFING_TYPE',
    payload: {
      id,
    },
  });

  dispatch(clearNextSteps('roofing'));
  dispatch(validateStepRoofing());
};

export const validateStepRoofing = () => (dispatch, getState) => {
  const { roofingTypes } = getState();
  dispatch(validateStep(isOneChecked(roofingTypes), 'roofing'));
};
