import styled from 'styled-components';

export const Label = styled.p`
  font-size: 1.8rem;
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.fontWeight.black};
  line-height: 1;
  margin-bottom: 8px;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    font-size: 1.6rem;
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    text-transform: none;
  }
`;

export const Controls = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 16px -16px 0;

  > * {
    margin: 0 16px;
  }
`;
