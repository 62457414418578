import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-top: 32px;

  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    border: 4px solid ${({ theme }) => theme.primary};
    padding: 32px;
    max-width: 680px;
    margin: 64px auto 0;
  }
`;

export const Title = styled.div`
  font-size: 2.2rem;
  font-weight: ${({ theme }) => theme.fontWeight.black};
  text-transform: uppercase;
  margin-bottom: 16px;

  span {
    color: ${({ theme }) => theme.primary};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    font-size: 4rem;
  }
`;

export const Content = styled.div`
  p {
    text-transform: uppercase;
    font-size: 1.6rem;
    font-weight: ${({ theme }) => theme.fontWeight.medium};

    @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
      font-size: 2rem;
    }
  }
`;
