import React from 'react';
import PropTypes from 'prop-types';
import { TabWrapper, TabHeader, Button, Title } from './Tab.styled';

const Tab = ({ path, number, title, handleClickTab, active, disabled }) => (
  <TabWrapper active={active} disabled={disabled}>
    <TabHeader>
      <Button onClick={() => handleClickTab(path)} disabled={disabled}>
        {number}
      </Button>
    </TabHeader>
    <Title>{title}</Title>
  </TabWrapper>
);

Tab.propTypes = {
  path: PropTypes.string.isRequired,
  number: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  handleClickTab: PropTypes.func.isRequired,
};

Tab.defaultProps = {
  active: false,
  disabled: false,
};

export default Tab;
