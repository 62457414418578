import React, { Component } from 'react';
import { WBoxContainer, WBoxRow, WBoxCol } from './WBoxResults.styled';
import Item from './Item/Item';

class WBoxResults extends Component {
  render() {
    const { windows } = this.props;
    const cols = windows[0].length;
    return (
      <>
        <WBoxContainer cols={cols} rows={windows.length}>
          {[...windows].reverse().map((row, rowIndex) => (
            <WBoxRow key={`boxresults-${rowIndex}`} cols={cols}>
              {row.map((item, colIndex) => (
                <WBoxCol key={`boxresultscol-${colIndex}`}>
                  <Item cols={cols} size={item} />
                </WBoxCol>
              ))}
            </WBoxRow>
          ))}
        </WBoxContainer>
      </>
    );
  }
}

export default WBoxResults;
