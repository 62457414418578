import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { MemoryRouter, Switch, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { theme } from './theme/theme';
import GlobalStyle from './theme/GlobalStyle';
import { fetchApp as fetchAppAction } from './store/actions';
import { Roofing, Distance, Windows, Summary } from './views';

import Loader from './components/Loader/Loader';
import Error from './components/Error/Error';
import ViewContent from './components/ViewContent/ViewContent';
import HTMLHead from './components/HTMLHead/HTMLHead';
import { getLangCode } from './helpers/lang';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import HeaderPage from './components/HeaderPage/HeaderPage';
import HeaderSteps from './components/HeaderSteps/HeaderSteps';
import { getActiveViewName } from './helpers/helpers';

class App extends Component {
  componentDidMount() {
    this.props.fetchApp();
  }

  renderHeader() {
    const activeView = getActiveViewName(this.props.activePath);

    if (activeView === 'summary' || activeView === 'error') {
      return <HeaderPage />;
    }
    return <HeaderSteps />;
  }

  render() {
    const { appError, appLoading, activePath, title, description } = this.props;

    return (
      <ThemeProvider theme={theme}>
        <MemoryRouter>
          <>
            <GlobalStyle />
            <HTMLHead lang={getLangCode()} title={title} description={description} />
            {appError ? (
              <Error />
            ) : appLoading ? (
              <Loader />
            ) : (
              <>
                <Navbar />
                {this.renderHeader()}
                <ViewContent>
                  <Switch location={{ pathname: activePath }}>
                    <Route path="/roofing" exact component={Roofing} />
                    <Route path="/distance" component={Distance} />
                    <Route path="/windows" component={Windows} />
                    <Route path="/summary" component={Summary} />
                  </Switch>
                </ViewContent>
                <Footer />
              </>
            )}
          </>
        </MemoryRouter>
      </ThemeProvider>
    );
  }
}

App.propTypes = {
  appLoading: PropTypes.bool.isRequired,
  appError: PropTypes.bool.isRequired,
  activePath: PropTypes.string.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
};

App.defaultProps = {
  title: '',
  description: '',
};

const mapStateToProps = ({ appError, appLoading, activePath, title, description }) => ({
  appError,
  appLoading,
  activePath,
  title,
  description,
});

const mapDispatchToProps = dispatch => {
  return {
    fetchApp: () => dispatch(fetchAppAction()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(App);
