import React, { Component } from 'react';
import { connect } from 'react-redux';
import Tooltip from '../../Tooltip/Tooltip';
import Select from '../../Select/Select';
import { Label, Controls } from './Add.styled';
import Button from '../../Button/Button';
import { addWindow as addWindowAction } from '../../../store/views/windows';
import { getCheckedItem } from '../../../helpers/helpers';

class Add extends Component {
  state = {
    selectedItem: 'none',
  };

  constructor(props) {
    super(props);
    this.tooltipRef = React.createRef();
  }

  handleChangeSize(event) {
    this.setState({ selectedItem: event.target.value });
  }

  handleClickAdd() {
    const { position, addWindow } = this.props;
    const { selectedItem } = this.state;

    if (selectedItem && selectedItem !== 'none') {
      addWindow(position, +selectedItem);
    }
    this.tooltipRef.current.hideTooltip();
  }

  handleClickClose() {
    this.setState({ selectedItem: 'none' });
    this.tooltipRef.current.hideTooltip();
  }

  getSize(sizeId) {
    const { sizes } = this.props;
    return sizes.find(size => size.id === sizeId);
  }

  getSizesList() {
    const { sizes, position, windows, layouts } = this.props;
    const y = position[0];
    const x = position[1];
    const layout = getCheckedItem(layouts);
    let sizeList = JSON.parse(JSON.stringify(sizes));
    const layoutOneColumn = +layout.id.split('x')[1] === 1;

    if (y > 0) {
      const prevXSize = this.getSize(windows[y - 1][x].sizeId);
      sizeList = sizeList.filter(size => {
        return size.dimension.width === prevXSize.dimension.width;
      });
    }

    if (x > 0) {
      const prevXSize = this.getSize(windows[y][x - 1].sizeId);
      sizeList = sizeList.filter(size => {
        return size.dimension.height === prevXSize.dimension.height;
      });
    }

    if (y > 0) {
      sizeList = sizeList.filter(size => !size.bottom);
    }

    if (layoutOneColumn) {
      sizeList = sizeList.filter(size => !size.hideColumn);
    }

    return sizeList.map(size => {
      size.title = `${size.title} (${size.dimension.width} x ${size.dimension.height} cm)`;

      return size;
    });
  }

  render() {
    const { translations, cols } = this.props;
    const sizeList = this.getSizesList();

    return (
      <Tooltip placement="auto" trigger="click" sizes cols={cols} innerRef={this.tooltipRef}>
        <Label>{translations.windowSize}</Label>
        <Select
          options={sizeList}
          value={this.state.selectedItem}
          disabled={sizeList.length === 0}
          onChange={event => this.handleChangeSize(event)}
          error={sizeList.length === 0 ? translations.changeAdjacentSizes : false}
        />
        <Controls>
          <Button icon="success" link smallSvg onClick={() => this.handleClickAdd()}>
            {translations.ok}
          </Button>
          <Button icon="close" link smallSvg onClick={() => this.handleClickClose()}>
            {translations.cancel}
          </Button>
        </Controls>
      </Tooltip>
    );
  }
}

const mapStateToProps = ({ activePath, views, viewLoading, translations, layouts }) => ({
  activePath,
  views,
  viewLoading,
  translations,
  layouts,
});

const mapDispatchToProps = dispatch => {
  return {
    addWindow: (position, sizeIdId) => dispatch(addWindowAction(position, sizeIdId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Add);
