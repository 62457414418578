import styled, { css } from 'styled-components';

export const TooltipButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  font-size: 0;

  svg {
    width: 16px;
    height: 16px;
    fill: ${({ theme }) => theme.primary};
    pointer-events: none;

    @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
      width: 18px;
      height: 18px;
    }
  }
`;

export const IconSize = styled.div`
  > div > div {
    display: flex;
    align-items: center;
  }

  svg {
    width: 26px;
    height: 26px;
    fill: ${({ theme }) => theme.primary};
  }
`;

export const TooltipButtonSize = styled.button`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: 4px solid ${({ theme }) => theme.primary};

  ${({ cols }) =>
    cols > 4 &&
    css`
      @media (max-width: ${({ theme }) => `${parseInt(theme.breakpoints.sm) - 1}px`}) {
        border-width: 2px;

        ${IconSize} {
          svg {
            width: 20px;
            height: 20px;
          }
        }
      }
    `}
`;

export const TooltipContainer = styled.div`
  border-radius: 0;
  border: none;
  padding: 18px;
  transition: opacity 0.3s;
  z-index: 999;
  max-width: 240px;

  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    max-width: 320px;
  }

  ${({ sizes }) =>
    sizes &&
    css`
      min-width: 300px;
      max-width: 320px;
    `}
`;

export const TooltipArrow = styled.div`
  height: 0;
  position: absolute;
  width: 0;

  ::before {
    position: absolute;
    content: '';
    display: block;
    height: 0;
    width: 0;
  }

  &[data-placement*='right'] {
    left: 0;
  }

  &[data-placement*='right']::before {
    border-top: 22px solid transparent;
    border-bottom: 22px solid transparent;

    border-right: 18px solid #fff;
    transform: translate3d(0, -50%, 0);
  }

  &[data-placement*='left'] {
    right: 18px;
  }

  &[data-placement*='left']::before {
    border-top: 22px solid transparent;
    border-bottom: 22px solid transparent;

    border-left: 18px solid #fff;
    transform: translate3d(0, -50%, 0);
  }

  &[data-placement*='top'] {
    left: 50%;
    top: 100%;
  }

  &[data-placement*='top']::before {
    border-left: 22px solid transparent;
    border-right: 22px solid transparent;

    border-top: 18px solid #fff;
    transform: translate3d(-50%, -18px, 0);
  }

  &[data-placement*='bottom'] {
    left: 50%;
    bottom: 100%;
  }

  &[data-placement*='bottom']::before {
    border-left: 22px solid transparent;
    border-right: 22px solid transparent;

    border-bottom: 18px solid #fff;
    transform: translate3d(-50%, 0, 0);
  }
`;

export const TooltipContent = styled.div`
  background-color: #fff;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.16);
  padding: 16px;
  font-size: 1.3rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    font-size: 1.6rem;
  }
`;
