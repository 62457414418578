import styled from 'styled-components';

export const TabsWrapper = styled.div`
  display: flex;
  justify-content: center;
  max-width: 460px;
  margin: 0 auto;
  position: relative;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    justify-content: space-between;
    max-width: ${({ large }) => (large ? '670px' : '500px')};

    &::before {
      content: '';
      position: absolute;
      top: 32px;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      width: 80%;
      height: 1px;
      border-bottom: 4px dotted ${({ theme }) => theme.primary};
    }
  }

  > * {
    flex: 0 0 25%;
  }
`;
