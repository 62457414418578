import styled, { css } from 'styled-components';

export const EmptyBox = styled.div`
  width: 100%;
  height: 100%;
  background-color: #ddd;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ add, theme }) =>
    add &&
    css`
      background-color: #fff;
      border: 4px solid ${() => theme.primary};
    `}
`;

export const Icon = styled.div`
  > div > div {
    display: flex;
    align-items: center;
  }

  svg {
    width: 26px;
    height: 26px;
    fill: ${({ theme }) => theme.primary};
  }
`;
