import { API_PATHS, axiosInstance } from '../../helpers/api';
import {
  appError,
  fetchViewStart,
  fetchTranslationsSuccess,
  fetchEmptyViewSuccess,
  validateStep,
} from '../actions';
import { fetchTranslations } from '../../helpers/translation';
import { getCheckedItem } from '../../helpers/helpers';

export const fetchView = () => async (dispatch, getState) => {
  dispatch(fetchViewStart());

  try {
    const { roofingTypes, distances } = getState();
    const roofingType = getCheckedItem(roofingTypes);
    const distance = getCheckedItem(distances);
    let sizes = [];

    if (roofingType && distance) {
      sizes = await axiosInstance
        .get(API_PATHS.sizes, {
          params: {
            combi_roofing_type: roofingType.id,
            'combi-distance': distance.id,
            per_page: 100,
          },
        })
        .then(response => {
          return response.data.map(size => ({
            id: size.id,
            title: size.title.rendered,
            bottom: !!size.acf.only_bottom,
            hideColumn: !!size.acf.hide_column,
            dimension: {
              width: size.acf.width,
              height: size.acf.height,
            },
          }));
        });
    }

    const translationsList = [
      { key: 'windowArrangement', value: 'Window arrangement' },
      { key: 'windowSize', value: 'Window size' },
      { key: 'ok', value: 'Ok' },
      { key: 'cancel', value: 'Cancel' },
      { key: 'row', value: 'Row' },
      { key: 'rows', value: 'Rows' },
      { key: 'window', value: 'Window' },
      { key: 'windows', value: 'Windows' },
      { key: 'changeAdjacentSizes', value: 'Please change adjacent sizes' },
    ];

    const translations = await fetchTranslations(translationsList);

    dispatch(fetchSizesSuccess(sizes));
    dispatch(fetchTranslationsSuccess(translations));
    dispatch(fetchEmptyViewSuccess('windows'));
    dispatch(fillLayout());
  } catch (e) {
    dispatch(appError());
  }
};

export const validateStepWindows = () => (dispatch, getState) => {
  const { windows } = getState();
  let valid = windows[0] && windows[0].length > 0;

  windows.forEach(row => {
    row.forEach(col => {
      if (col.type !== 'item') {
        valid = false;
      }
    });
  });

  dispatch(validateStep(valid, 'windows'));
};

export const addWindow = (position, sizeId) => dispatch => {
  dispatch({
    type: 'ADD_WINDOW',
    payload: {
      position,
      sizeId,
    },
  });

  dispatch(fillAddWindows());
  dispatch(validateStepWindows());
};

export const fetchSizesSuccess = data => ({
  type: 'FETCH_SIZES_SUCCESS',
  payload: {
    data,
  },
});

export const fillAddWindows = () => ({
  type: 'FILL_ADD_WINDOWS',
});

export const changeLayout = id => dispatch => {
  dispatch({
    type: 'CHANGE_LAYOUT',
    payload: {
      id,
    },
  });

  dispatch(fillLayout());
  dispatch(validateStepWindows());
};

export const fillLayout = () => ({
  type: 'FILL_LAYOUT',
});

export const removeWindow = position => dispatch => {
  dispatch({
    type: 'REMOVE_WINDOW',
    payload: {
      position,
    },
  });

  dispatch(fillAddWindows());
  dispatch(validateStepWindows());
};
