import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Header from '../Header/Header';
import HeaderDescription from '../HeaderDescription/HeaderDescription';
import Tabs from '../Tabs/Tabs';
import { getActiveViewName } from '../../helpers/helpers';
import { changeView as changeViewAction } from '../../store/actions';

class HeaderSteps extends Component {
  isPrevStepsValid(currentStepName) {
    const { steps, views } = this.props;
    const currentStepIndex = steps.indexOf(currentStepName);

    if (currentStepIndex > 0) {
      for (let i = currentStepIndex - 1; i >= 0; i--) {
        if (!views[steps[i]].valid) {
          return false;
        }
      }
    }

    return true;
  }

  getStepsData() {
    const { activePath, steps, views } = this.props;
    const stepsData = [];
    const activeStep = getActiveViewName(activePath);

    steps.forEach((stepName, index) => {
      const step = views[stepName];
      const active = stepName === activeStep;
      stepsData.push({
        path: step.path,
        number: index + 1,
        title: step.header.title,
        active,
        disabled: active ? false : !this.isPrevStepsValid(stepName),
      });
    });

    return stepsData;
  }

  render() {
    const { activePath, views, changeView } = this.props;

    const headerDescription = views[getActiveViewName(activePath)].header.description;

    return (
      <Header>
        <Tabs items={this.getStepsData()} handleClickTab={path => changeView(path)} />
        {headerDescription && (
          <HeaderDescription dangerouslySetInnerHTML={{ __html: `${headerDescription}` }} />
        )}
      </Header>
    );
  }
}

HeaderSteps.propTypes = {
  activePath: PropTypes.string.isRequired,
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
  views: PropTypes.shape().isRequired,
  changeView: PropTypes.func.isRequired,
};

const mapStateToProps = ({ activePath, steps, views }) => ({
  activePath,
  steps,
  views,
});

const mapDispatchToProps = dispatch => {
  return {
    changeView: path => dispatch(changeViewAction(path)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HeaderSteps);
