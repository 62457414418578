import { API_PATHS, axiosInstance } from '../helpers/api';
import { fetchTranslations } from '../helpers/translation';
import { clearResponseSettings } from './clearResponse';

export const fetchAppStart = () => ({
  type: 'FETCH_APP_START',
});

export const fetchAppSuccess = data => ({
  type: 'FETCH_APP_SUCCESS',
  payload: {
    data,
  },
});

export const appError = () => ({
  type: 'APP_ERROR',
});

export const resetApp = () => ({
  type: 'RESET_APP',
});

export const fetchApp = () => async dispatch => {
  dispatch(fetchAppStart());

  try {
    const pageData = await axiosInstance.get(API_PATHS.settings).then(response => {
      return clearResponseSettings(response.data);
    });

    const translationsList = [
      { key: 'startOver', value: 'Start over' },
      { key: 'nextStep', value: 'Next step' },
      { key: 'complete', value: 'Complete' },
      { key: 'whatsNext', value: 'What&#39;s next?' },
      { key: 'whereToBuy', value: 'Where to buy' },
      { key: 'selectFromList', value: 'Select from the list...' },
    ];

    const translations = await fetchTranslations(translationsList);

    dispatch(fetchTranslationsSuccess(translations));
    dispatch(fetchAppSuccess(pageData));
  } catch (e) {
    dispatch(appError());
  }
};

export const fetchViewStart = () => ({
  type: 'FETCH_VIEW_START',
});

export const fetchEmptyViewSuccess = view => ({
  type: 'FETCH_EMPTY_VIEW_SUCCESS',
  payload: {
    view,
  },
});

export const fetchTranslationsSuccess = data => ({
  type: 'FETCH_TRANSLATIONS_SUCCESS',
  payload: {
    data,
  },
});

export const changeView = path => {
  return {
    type: 'CHANGE_VIEW',
    payload: {
      path,
    },
  };
};

export const nextStep = () => ({
  type: 'NEXT_STEP',
});

export const clearNextSteps = currentStepName => {
  return {
    type: 'CLEAR_NEXT_STEPS',
    payload: {
      currentStepName,
    },
  };
};

export const validateStep = (valid, stepName) => {
  return {
    type: 'VALIDATE_STEP',
    payload: {
      valid,
      stepName,
    },
  };
};

export const goToSummary = () => ({
  type: 'GO_TO_SUMMARY',
});
