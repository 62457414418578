import styled, { css } from 'styled-components';
import { Col } from '../../layout';

export const DistanceCol = styled(Col)`
  margin-bottom: 12px;

  :last-of-type {
    margin-bottom: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-basis: 50%;
    margin-bottom: 24px;
  }

  ${({ image }) =>
    image &&
    css`
      margin-top: 24px;

      @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
        order: -1;
        text-align: right;
        margin-top: 0;
      }
    `}
`;

export const DistanceItem = styled.div`
  margin-bottom: 16px;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    max-width: 250px;
  }
`;

export const DistanceImg = styled.img`
  display: inline-block;
  max-width: 100%;
  height: auto;
`;
