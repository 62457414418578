import {
  appError,
  fetchViewStart,
  fetchTranslationsSuccess,
  fetchEmptyViewSuccess,
} from '../actions';
import { fetchTranslations } from '../../helpers/translation';

export const fetchView = () => async dispatch => {
  dispatch(fetchViewStart());
  try {
    const translationsList = [
      { key: 'selectedWindows', value: 'Selected windows' },
      { key: 'configuratorResults', value: 'Configurator <span>results</span>' },
      { key: 'ucxUrlTitle', value: 'Learn more about UCX flashing' },
      { key: 'scxUrlTitle', value: 'Learn more about SCX flashing' },
      { key: 'ubxUrlTitle', value: 'Learn more about UBX flashing' },
    ];

    const translations = await fetchTranslations(translationsList);

    dispatch(fetchTranslationsSuccess(translations));
    dispatch(fetchEmptyViewSuccess('summary'));
  } catch (e) {
    dispatch(appError());
  }
};
