import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  goToSummary as goToSummaryAction,
  nextStep as nextStepAction,
  resetApp as resetAppAction,
} from '../../store/actions';
import Button from '../Button/Button';
import {
  FooterWrapper,
  FooterContainer,
  FooterRow,
  FooterCol,
  TitleFooter,
  FooterButtons,
} from './Footer.styled';
import { getActiveViewName } from '../../helpers/helpers';

class Footer extends Component {
  renderSteps = () => {
    const { activePath, views, viewLoading, translations, nextStep, resetApp } = this.props;
    const viewValid = views[getActiveViewName(activePath)].valid;
    return (
      <>
        <FooterCol>
          <Button icon="reset" link onClick={() => resetApp()} disabled={viewLoading}>
            {translations.startOver}
          </Button>
        </FooterCol>
        <FooterCol right>
          <Button
            style={{ marginLeft: '24px' }}
            onClick={() => nextStep()}
            disabled={viewLoading || !viewValid}
          >
            {translations.nextStep}
          </Button>
        </FooterCol>
      </>
    );
  };

  renderStepsComplete = () => {
    const { activePath, views, viewLoading, translations, goToSummary, resetApp } = this.props;
    const viewValid = views[getActiveViewName(activePath)].valid;
    return (
      <>
        <FooterCol>
          <Button icon="reset" link onClick={() => resetApp()} disabled={viewLoading}>
            {translations.startOver}
          </Button>
        </FooterCol>
        <FooterCol right>
          <Button outline onClick={() => goToSummary()} disabled={viewLoading || !viewValid}>
            {translations.complete}
          </Button>
        </FooterCol>
      </>
    );
  };

  renderSummary = () => {
    const { viewLoading, urlWhereToBuy, translations, resetApp } = this.props;
    return (
      <>
        <FooterCol>
          <TitleFooter dangerouslySetInnerHTML={{ __html: `${translations.whatsNext}` }} />
        </FooterCol>
        <FooterCol right>
          <FooterButtons>
            <Button as="a" href={urlWhereToBuy} link large target="_blank" disabled={viewLoading}>
              {translations.whereToBuy}
            </Button>
            <Button icon="reset" link large onClick={() => resetApp()} disabled={viewLoading}>
              {translations.startOver}
            </Button>
          </FooterButtons>
        </FooterCol>
      </>
    );
  };

  renderContent() {
    switch (getActiveViewName(this.props.activePath)) {
      case 'roofing':
        return this.renderSteps();
      case 'distance':
        return this.renderSteps();
      case 'windows':
        return this.renderStepsComplete();
      case 'summary':
        return this.renderSummary();
      default:
        return null;
    }
  }

  render() {
    const footerContent = this.renderContent();
    return (
      footerContent && (
        <FooterWrapper>
          <FooterContainer>
            <FooterRow>{footerContent}</FooterRow>
          </FooterContainer>
        </FooterWrapper>
      )
    );
  }
}

const mapStateToProps = ({ activePath, viewLoading, views, urlWhereToBuy, translations }) => ({
  activePath,
  viewLoading,
  views,
  urlWhereToBuy,
  translations,
});

const mapDispatchToProps = dispatch => {
  return {
    resetApp: () => dispatch(resetAppAction()),
    goToSummary: () => dispatch(goToSummaryAction()),
    nextStep: () => dispatch(nextStepAction()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Footer);
