import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import axios from 'axios';
import { Container } from '../../layout';
import Brand from './Brand/Brand';
import Content from './Content/Content';
import Title from './Title/Title';
import Controls from './Controls/Controls';
import { getLangCode } from '../../helpers/lang';

const Nav = styled.nav`
  box-shadow: ${({ theme }) => theme.boxShadow.default};
  z-index: ${({ theme }) => theme.zIndex.nav};
  background-color: #fff;
`;

const ContainerWrapper = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;

  @media (min-width: ${({ theme }) => theme.breakpoints[theme.navbarBreakpoint]}) {
    height: 100px;
  }
`;

class Navbar extends Component {
  state = {
    open: false,
    items: [],
  };

  componentDidMount() {
    const apiUrlMenu = process.env.REACT_APP_DEVELOPMENT_API_URL_MENU
      ? process.env.REACT_APP_DEVELOPMENT_API_URL_MENU
      : '/wp-json/wp/v2/menu';
    axios
      .get(`${apiUrlMenu}?lang=${getLangCode()}`)
      .then(response => {
        this.setState(() => ({ items: response.data }));
      })
      .catch(error => {
        console.log(error);
      });
  }

  handleDropdownClick(id) {
    this.setState(state => {
      const items = state.items.map(item => {
        if (item.id === id) {
          item.open = !item.open;
        }

        return item;
      });

      return {
        items,
      };
    });
  }

  handleHamburgerClick() {
    this.setState(state => ({
      open: !state.open,
    }));
  }

  render() {
    const { open, items } = this.state;
    const { navbarTitle } = this.props;

    return (
      <Nav>
        <ContainerWrapper>
          <Brand />
          <Content
            open={open}
            items={items}
            dropdownClickFn={itemIndex => this.handleDropdownClick(itemIndex)}
          />
          {navbarTitle && <Title>{navbarTitle}</Title>}
          <Controls open={open} handleHamburgerClickFn={() => this.handleHamburgerClick()} />
        </ContainerWrapper>
      </Nav>
    );
  }
}

const mapStateToProps = ({ navbarTitle }) => ({
  navbarTitle,
});

export default connect(mapStateToProps)(Navbar);
