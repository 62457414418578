const initialState = {
  title: null,
  description: null,
  navbarTitle: null,
  urlWhereToBuy: null,
  appError: false,
  appLoading: true,
  activePath: '/',
  viewLoading: true,
  translations: {},
  steps: ['roofing', 'distance', 'windows'],
  views: {},
  layouts: [
    { id: '1x2', checked: false },
    { id: '1x3', checked: false },
    { id: '1x4', checked: false },
    { id: '2x1', checked: false },
    { id: '2x2', checked: false },
    { id: '2x3', checked: false },
    { id: '2x4', checked: false },
    { id: '3x1', checked: false },
    { id: '3x2', checked: false },
    { id: '3x3', checked: false },
    { id: '3x4', checked: false },
    { id: '4x1', checked: false },
    { id: '4x2', checked: false },
    { id: '4x3', checked: false },
    { id: '4x4', checked: false },
  ],
  windows: [],
  sizes: [],
  roofingTypes: [],
  distances: [],
};

export default initialState;
