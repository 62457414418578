import { API_PATHS, axiosInstance } from '../../helpers/api';
import {
  appError,
  fetchViewStart,
  fetchTranslationsSuccess,
  validateStep,
  fetchEmptyViewSuccess,
  clearNextSteps,
} from '../actions';
import { fetchTranslations } from '../../helpers/translation';
import { isOneChecked } from '../../helpers/store';

export const fetchView = () => async (dispatch, getState) => {
  dispatch(fetchViewStart());

  const { roofingTypes } = getState();
  const checkedRoofingType = roofingTypes.find(el => el.checked);
  const distances = [];

  if (checkedRoofingType) {
    await Promise.all(
      checkedRoofingType.distances.map(distanceId => {
        return axiosInstance.get(`${API_PATHS.distance}/${distanceId}`).then(response => {
          const {
            data: { id, acf },
          } = response;
          return {
            id,
            title: acf.label,
            tooltip: acf.tooltip,
            code: acf.letter ? acf.letter : '',
            type: acf.type,
            horizontal: !!acf.horizontal,
            vertical: !!acf.vertical,
            checked: false,
          };
        });
      }),
    )
      .then(response => {
        distances.push(...response);
      })
      .catch(() => {
        dispatch(appError());
      });
  }

  const translationsList = [{ key: 'horizontalDistance', value: 'Horizontal distance' }];

  const translations = await fetchTranslations(translationsList);

  dispatch(fetchDistancesSuccess(distances));
  dispatch(fetchTranslationsSuccess(translations));
  dispatch(fetchEmptyViewSuccess('distance'));
};

export const fetchDistancesSuccess = data => ({
  type: 'FETCH_DISTANCES_SUCCESS',
  payload: {
    data,
  },
});

export const setDistance = id => dispatch => {
  dispatch({
    type: 'SET_DISTANCE',
    payload: {
      id,
    },
  });

  dispatch(clearNextSteps('distance'));
  dispatch(validateStepDistance());
};

export const validateStepDistance = () => (dispatch, getState) => {
  const { distances } = getState();
  dispatch(validateStep(isOneChecked(distances), 'distance'));
};
